

























































































































































































































import api from "@/api/index"; //ABP API接口
import {Component, Ref, Vue} from "vue-property-decorator";
import PagedTableView from "@/components/PagedTableView/index.vue";
import {
  DataDictionaryDtoPagedResultDto,
  DataDictionaryDto,
  TrainClearanceSequenceDto,
  TrainDto
} from "@/api/appService";
import AbSelect from "@/components/AbSelect/index.vue";
import {getTrainStatusList} from "@/utils/enumToSelectItem";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";
import ExportButton from "@/components/ExportButton/index.vue";
import {ElForm} from "element-ui/types/form";

@Component({
  name: "TrainList",
  components: {
    ExportButton,
    PagedTableView,
    AbSelect,
    AgileAuditTag
  }
})
export default class TrainList extends Vue {
  @Ref() readonly dataForm!: ElForm;
  statusList = getTrainStatusList();
  typeList: DataDictionaryDto[] = [];
  queryForm: any = {
    title: undefined,
    trainTypeId: undefined,
    status: undefined,
    startDate: undefined,
    endDate: undefined
  }
  exportFieldList = [
    "培训名称",
    "培训类型",
    "主办方",
    "培训讲师",
    "培训开始时间",
    "培训结束时间",
    "积分设置",
    "报名截止时间",
    "志愿者星级",
    "志愿者积分",
    "限制报名人数",
    "是否人工审核",
    "培训详情",
    "章节列表"
  ];

  visible = false;
  innerVisible = false;
  loading = false;
  innerLoading = false;
  trainClearanceSequenceList: TrainClearanceSequenceDto[] = [];
  trainList: TrainDto[] = [];
  form:TrainClearanceSequenceDto={
    trainId:undefined,
    sequence:undefined,
}
  formRules = {
    trainId: [
      {
        required: true,
        message: "请选择培养主题",
        trigger: "blur",
      },
    ],
    sequence: [
      {
        required: true,
        message: "请输入第几课",
      },
    ],
  };
  created() {
    this.fetchDataDictionary()
  }

  // 获取表数据
  async fetchData(params: any) {
    return await api.train.getAll(params);
  }

  async fetchDataDictionary() {
    await api.dataDictionary
      .getDataDictionaryListByKey({key: "TrainType", maxResultCount: 1000})
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.typeList = res.items!;
      });
  }

  handleCreate() {
    this.$router.push({name: 'trainCreate'})
  }


  // 跳转详情页
  handleDetail(item: any) {
    this.$router.push({name: 'trainDetail', params: {'id': item.id!.toString()}})
  }

  // 考题管理
  handleTrainExamList(item: any) {
    this.$router.push({name: 'trainExamList', params: {'id': item.id!.toString()}})
  }

  // 跳转编辑页
  handleEdit(item: any) {
    this.$router.push({name: 'trainEdit', params: {'id': item.id!.toString()}})
  }

  handleDelete(item: any) {
    this.$confirm('确定删除吗?', '提示').then(() => {
      api.train.delete({id: item.id}).then(res => {
        this.$message.success('删除成功');
        (this as any).$bus.$emit('refresh-data');
      });
    })
  }
  handleDeleteClearanceSequence(item: any) {
console.log(item);
    this.$confirm('确定删除吗?', '提示').then(() => {
      api.train.deleteTrainClearanceSequence({id: item.id}).then(res => {
        this.$message.success('删除成功');
        this.loading=true
        api.train.getTrainClearanceSequenceList().then((res)=>{
          this.trainClearanceSequenceList=res
          this.loading=false
        })
      });
    })
  }

  handlePublish(item: any) {
    this.$confirm('确定发布吗?', '提示').then(() => {
      api.train.publish({body:{id: item.id}}).then(res => {
        this.$message.success('发布成功');
        (this as any).$bus.$emit('refresh-data');
      });
    })
  }
  handleShowLog() {
    this.visible = true;
    this.loading=true
    api.train.getTrainClearanceSequenceList().then((res)=>{
      this.trainClearanceSequenceList=res
      this.loading=false
    })
  }
  handleAddClearanceSequence() {
    this.innerVisible = true;
    this.innerLoading = true;
    api.train.getAll({maxResultCount:65535}).then((res)=>{

     if(res&&res.items){
       this.trainList=res.items
     }
      this.innerLoading=false

    })
  }


  async save() {
    console.log(this.form);

    this.dataForm.validate(async (valid: boolean) => {
      if (valid) {
          await api.train.setTrainClearanceSequence({ body: this.form }).then(() => {
            this.$message.success("更新成功");
          });
        this.innerVisible = false;
       this.handleShowLog();
      }
    });
  }
  // 取消发布
  handleCancelPublish(item: any) {
    let confirmMessage = '确定取消发布吗?';
    if ((item.signUpCount ?? 0) > 0) {
      confirmMessage = '确定取消发布吗?当前培养已有用户报名，取消培养会造成报名用户无法继续培养，请谨慎操作';
    }
    this.$confirm(confirmMessage, '提示').then(() => {
      api.train.cancelPublish({id: item.id}).then(res => {
        this.$message.success('取消发布成功');
        (this as any).$bus.$emit('refresh-data');
      });
    })
  }
}
